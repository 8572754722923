<template>
  <Modal
    :show="true"
    :title="$t('common.switch_company')"
    :width="500"
    vertical-position="center"
    @close="emit('close')"
  >
    <SelectInput
      v-model="selectedCompany"
      :items="companies"
      key-label="label"
      key-value="id"
      @change="changeCompany"
    />
  </Modal>
</template>

<script setup>
import Modal from '@/Components/Modal.vue'
import SelectInput from '@/Components/common/SelectInput.vue'
import { useRoute } from '@/composables/route'
import { useForm, usePage } from '@inertiajs/vue3'
import { computed, ref } from 'vue'
const { useI18n } = await import('vue-i18n')
const { t } = useI18n()

const emit = defineEmits(['close'])

const user = usePage().props._user
const selectedCompany = ref(user.active_company?.id)
const form = useForm({})

const companies = computed(() => {
  return usePage().props._companies.map((company) => {
    company.label = company.name

    if (company.is_support_access) {
      company.label += ' (' + t('common.support_access') + ')'
    }

    if (company.deactivated) {
      company.label += ` (${t('company.inactive')})`
    }

    return company
  })
})

const changeCompany = async (company) => {
  form.post(
    useRoute('companies.setActive', {
      company,
    }),
  )
  emit('close')
}
</script>
