import { useRoute } from '@/composables/route'
import { useEcho } from '@/composables/useEcho'
import { getJson, putJson, deleteJson } from '@/composables/useFetch'
import { usePage } from '@inertiajs/vue3'
import { defineComponent, ref, onMounted, computed } from 'vue'

export default defineComponent({
  emits: ['new-notification'],

  setup(props, { slots, emit }) {
    const notifications = ref([])
    const unread = computed(() =>
      notifications.value.filter(({ read }) => !read),
    )
    const unreadCount = computed(() => unread.value.length)
    const hasUnread = computed(() => unreadCount.value > 0)

    onMounted(async () => {
      const { data, statusCode } = await getJson(
        useRoute('users.notifications.index'),
      )

      if (statusCode !== 200) {
        notifications.value = []
        return
      }

      notifications.value = data.value.notifications
    })

    useEcho()
      .private(`users.${usePage().props._user.id}`)
      .notification((notification) => {
        const newNotification = { ...notification, read: false }
        notifications.value.unshift(newNotification)
        emit('new-notification', newNotification)
      })

    const markAsRead = async ({ id }) => {
      const { data } = await putJson(
        useRoute('users.notifications.markAsRead', { notification: id }),
      )
      notifications.value = data.value.notifications
    }
    const markAllAsRead = async () => {
      const { data } = await putJson(
        useRoute('users.notifications.markAllAsRead'),
      )
      notifications.value = data.value.notifications
    }
    const markAsUnread = async ({ id }) => {
      const { data } = await putJson(
        useRoute('users.notifications.markAsUnread', { notification: id }),
      )
      notifications.value = data.value.notifications
    }
    const deleteNotification = async ({ id }) => {
      const { data } = await deleteJson(
        useRoute('users.notifications.destroy', { notification: id }),
      )
      notifications.value = data.value.notifications
    }
    const deleteAllNotification = async () => {
      await deleteJson(useRoute('users.notifications.destroyAll'))
      notifications.value = []
    }

    return () =>
      slots.default({
        list: notifications.value,
        unread: {
          has: hasUnread.value,
          count: unreadCount.value,
          list: unread.value,
        },
        markAsRead: {
          one: markAsRead,
          all: markAllAsRead,
        },
        markAsUnread: {
          one: markAsUnread,
        },
        delete: {
          one: deleteNotification,
          all: deleteAllNotification,
        },
      })
  },
})
