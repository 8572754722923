// import { useChatwootCredentials } from '@/composables/credentials'
// import { useSimplifiedCurrentLocale } from '@/composables/locale'
// import { useWindow, useChatwoot } from '@/composables/useWindow'
// import { usePage } from '@inertiajs/vue3'
import { defineComponent } from 'vue'
// import { useI18n } from 'vue-i18n'

// const useChatwootSDK = () => useWindow('chatwootSDK')

export default defineComponent({
  setup() {
    const script = document.createElement('script')
    script.id = 'ze-snippet'
    script.defer = true
    script.async = true
    script.setAttribute(
      'src',
      'https://static.zdassets.com/ekr/snippet.js?key=a90bfc2c-d8a3-406a-80d4-7a7a47650030',
    )
    document.body.appendChild(script)

    // initSettings()
    //
    // const { base_url, website_token } = useChatwootCredentials()
    //
    // const script = document.createElement('script')
    // script.defer = true
    // script.async = true
    // script.setAttribute('src', `${base_url}/packs/js/sdk.js`)
    // document.body.appendChild(script)
    //
    // script.onload = () =>
    //   useChatwootSDK().run({
    //     websiteToken: website_token,
    //     baseUrl: base_url,
    //   })
    //
    // window.addEventListener('chatwoot:ready', () => {
    //   if (!usePage().props._user) {
    //     return
    //   }
    //
    //   configureUser()
    // })

    return () => {}
  },
})

// const initSettings = () => {
//   const { t } = useI18n()
//
//   window.chatwootSettings = {
//     locale: useSimplifiedCurrentLocale(),
//     useBrowserLanguage: false,
//     launcherTitle: t('chat_launcher_title'),
//   }
// }

// const configureUser = () => {
//   const user = usePage().props._user
//
//   useChatwoot().setUser(user.id, {
//     identifier_hash: useChatwootCredentials().user_identifier_hash,
//     name: user.full_name,
//     avatar_url: user.avatar?.attributes?.original_url ?? null,
//     email: user.email,
//     phone_number: user.phone,
//     company_name: user.subscription_company?.name,
//   })
// }
